import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeColumn = _resolveComponent("PrimeColumn")!
  const _component_PrimeDataTable = _resolveComponent("PrimeDataTable")!
  const _component_h = _resolveComponent("h")!
  const _component_GMapInfoWindow = _resolveComponent("GMapInfoWindow")!
  const _component_GMapMarker = _resolveComponent("GMapMarker")!
  const _component_GMapMap = _resolveComponent("GMapMap")!

  return (_openBlock(), _createBlock(_component_GMapMap, {
    center: {lat: _ctx.center.lat, lng: _ctx.center.lng},
    zoom: 15,
    style: {"width":"100%","height":"65%","position":"absolute"},
    ref: "myMapRef",
    disableDefaultUI: true,
    options: _ctx.option
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.markers, (m, index) => {
        return (_openBlock(), _createBlock(_component_GMapMarker, {
          position: m.position,
          clickable: true,
          draggable: false,
          icon: { url: require(`../assets/clubs//${m.icon ? m.icon : 'UFO.png' }`)},
          key: index,
          onClick: ($event: any) => (_ctx.openMarker(m.id)),
          onCloseclick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openMarker(null)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_GMapInfoWindow, {
              opened: _ctx.openedMarkerID === m.id,
              options: {pixelOffset: {width: 10, height: 0},maxWidth: 320,maxHeight: 320,}
            }, {
              default: _withCtx(() => [
                (m.event && m.event.length > 0)
                  ? (_openBlock(), _createBlock(_component_PrimeDataTable, {
                      key: 0,
                      class: "p-datatable-sm",
                      value: m.event,
                      paginator: true,
                      rows: 3,
                      paginatorTemplate: "PrevPageLink  NextPageLink",
                      responsiveLayout: "scroll"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_PrimeColumn, { field: "name" }),
                        _createVNode(_component_PrimeColumn, null, {
                          body: _withCtx((slotProps) => [
                            _createElementVNode("span", null, _toDisplayString(new Date(slotProps.data.from).toLocaleDateString()), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_PrimeColumn, null, {
                          body: _withCtx((slotProps) => [
                            _createElementVNode("span", null, [
                              _createElementVNode("a", {
                                href: slotProps.data.fb
                              }, [
                                _createElementVNode("img", {
                                  class: "icon",
                                  src: require(`../assets/FB.png`),
                                  alt: "",
                                  style: {"width":"13px","height":"13px","float":"right"}
                                }, null, 8, _hoisted_2)
                              ], 8, _hoisted_1)
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_h, { style: {"color":"black","font-family":"AlternateGothicProCyrillic","opacity":"1"} }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(m.name), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["opened"])
          ]),
          _: 2
        }, 1032, ["position", "icon", "onClick"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["center", "options"]))
}